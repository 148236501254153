import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import Payments from "./payments";
import { Link } from "react-router-dom";
import AlumniExportButton from "./adminExcel";
import PaymentExportButton from "./paymentExcel";

function AdminDashboard() {
  const authToken = localStorage.getItem("HCAA-TOKEN") || null;
  const [alumniData, setAlumniData] = useState([]);
  const [filterAlumniData, setFilterAlumniData] = useState([]);
  const [isShow, setShow] = useState(true);
  const [count, setCount] = useState(0);
  const printableTableRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printableTableRef.current,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://hcaa-backend-v7.onrender.com/v4/alumni",
          {
            headers: {
              Authorization: authToken,
            },
          }
        );

        setAlumniData(response.data.alumni);
        setFilterAlumniData(response.data.alumni);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, [authToken, count]);

  const handleSingle = (id) => {
    fetchData(id);
  };

  const fetchData = async (id) => {
    try {
      const response = await axios.get(
        `https://hcaa-backend-v7.onrender.com/v4/alumni/${id}`,
        {
          headers: {
            Authorization: authToken,
          },
        }
      );
      if (response.status === 200) {
        localStorage.setItem("ALUMNI", JSON.stringify(response.data));
        setTimeout(() => {
          window.location.href = `/admin_alumni`;
        }, 1000);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handleShow = () => {
    setCount((prev) => count + 1);
  };
  const handleNotShow = () => {
    setShow(false);
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    const filteredData = alumniData.filter(
      (el) =>
        el.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        el.batch.toLowerCase().includes(searchTerm.toLowerCase()) ||
        el.gender.toLowerCase().includes(searchTerm.toLowerCase()) ||
        el.food.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (el.paymentDetails &&
          el.paymentDetails.some((payment) =>
            payment.transactionID
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          ))
    );
    setFilterAlumniData(filteredData);
  };

  const handleShowNotPaid = () => {
    const notpaid = alumniData?.filter((el) => el.paymentDetails.length === 0);
    setFilterAlumniData([...notpaid]);
  };

  const nonpaidVeg = alumniData?.filter(
    (el) => el.paymentDetails.length === 0 && el.food === "Veg"
  );

  const handleShowPaidFive = () => {
    const fiveHundred = alumniData.filter(
      (el) =>
        el.paymentDetails.length > 0 && el.paymentDetails[0].amount === 500
    );

    setFilterAlumniData(fiveHundred);
  };
  const handleShowPaidOneFive = () => {
    const OneThousandfiveHundred = alumniData.filter(
      (el) =>
        el.paymentDetails.length > 0 && el.paymentDetails[0].amount === 1500
    );

    setFilterAlumniData(OneThousandfiveHundred);
  };
  const handleShowPaidThree = () => {
    const threeThousand = alumniData.filter(
      (el) =>
        el.paymentDetails.length > 0 && el.paymentDetails[0].amount === 3000
    );

    setFilterAlumniData(threeThousand);
  };

  return (
    <section id="gallery" className="gallery">
      <div className="container mt-5">
        <div className="section-title" data-aos="fade-up">
          <h2>Dashboard</h2>
          <p>ADMIN DASHBOARD</p>
          {authToken && <h4>ADMIN</h4>}
        </div>
        <div className="row admin">
          <div className="admin-upper">
            <div className="btn btn-light" onClick={handleShow}>
              All
            </div>
            <div className="btn btn-light" onClick={handleShowNotPaid}>
              Not Paid
            </div>
            <div>
              <AlumniExportButton />
            </div>
            <div>
              <PaymentExportButton />
            </div>
            {/* <div onClick={handleNotShow}>Payments</div> */}
            <Link to="/admin_notice">
              <div className="btn btn-light w-100">Notice Form</div>
            </Link>
            <div className="btn btn-light" onClick={handleShowPaidFive}>
              Paid 500
            </div>
            <div className="btn btn-light" onClick={handleShowPaidOneFive}>
              Paid 1500
            </div>
            <div className="btn btn-light" onClick={handleShowPaidThree}>
              Paid 3000
            </div>
          </div>

          <div style={{ marginTop: "1rem" }}>
            <input
              type="text"
              placeholder="Search Event Type Here"
              onChange={handleSearch}
              className="form-control"
            />
            <b>Total : {filterAlumniData.length} members found</b>{" "}
            <button onClick={handlePrint} className="btn btn-info mt-2">
              Print
            </button>
          </div>
          <div className="admin-lower">
            {isShow ? (
              <div ref={printableTableRef}>
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Sl No.</th>
                      <th scope="col">Member Name</th>
                      <th scope="col">Contact No.</th>
                      <th scope="col">Batch</th>
                      <th scope="col">Gender</th>
                      <th scope="col">Food</th>
                      <th scope="col">Passout Year</th>
                      <th scope="col">Transaction ID</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Payment Date</th>
                      <th scope="col">Verification</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterAlumniData.map((el, i) => {
                      if (el.batch === "ISC") {
                        el.batch = "ICSE";
                        if (el.year) {
                          el.year -= 2;
                        }
                      }

                      return (
                        <tr onClick={() => handleSingle(el._id)} key={el._id}>
                          <td>
                            <b>{i + 1}</b>
                          </td>
                          <td>{el.name}</td>
                          {el.phoneNumber?.toString().length > 10 ? (
                            <td>+{el.phoneNumber} </td>
                          ) : (
                            <td>{el.phoneNumber}</td>
                          )}
                          <td>{el.batch}</td>
                          <td>{el.gender}</td>
                          <td>{el.food}</td>
                          <td>{el.year}</td>
                          <td>
                            <b>
                              {el.paymentDetails?.map((e) => e.transactionID)}
                            </b>{" "}
                          </td>
                          <td>{el.paymentDetails?.map((e) => e.amount)}</td>
                          <td>{el.paymentDetails?.map((e) => e.date)}</td>
                          <td>
                            {el.paymentDetails?.map((e) =>
                              e.isVerified === false
                                ? "Under Verification"
                                : "Verified"
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <Payments props={isShow} />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdminDashboard;
