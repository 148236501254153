import React, { useEffect, useState } from 'react'
import jwt_decode from "jwt-decode";
import axios from 'axios'

const Donor = () => {
  const donorToken = localStorage.getItem("DONATION-TOKEN") || null;
  var decodedJwt = jwt_decode(donorToken);
  const [Donor, setDonor] = useState([]);
  const [isLoading,setIsLoading] = useState(false)
    
  useEffect(() => {
    const fetchDonorData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `http://127.0.0.1:8801/v4/donation/${decodedJwt.donationId}`
        )
        if (response) {
          setDonor(response.data);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.log("Error fetching Donor data:");
      }
    };
    fetchDonorData();
  }, []);

console.log(Donor)



  return (
    <section id="gallery" className="gallery">
      <div className="container my-5">
        <div className="section-title" data-aos="fade-up">
          <h2>dashboard</h2>
          <p>Welcome {Donor.name}</p>
        </div>
        <div className="card  Donor-table" style={{ marginTop: "-2rem" }}>
          <div className="card-body">
            <table className="table table-bordered ">
              <tbody>
                <tr>
                  <th>Name</th>
                  <td>{Donor.name}</td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{Donor.email}</td>
                </tr>
                {Donor && (
                  <tr>
                    <th>Mobile</th>
                    <td>{Donor.phoneNumber}</td>
                  </tr>
                )}
                {Donor && (
                  <tr>
                    <th>Address</th>
                    <td>{Donor.Address}</td>
                  </tr>
                )}

                <tr>
                  <th>Donation </th>
                  <td>
                    {Donor.payment?.length > 0 ? (
                      "under verification"
                    ) : (
                      <>
                        {" "}
                        <b>Not Paid</b>{" "}
                        <a href="/donation-payment">
                          {" "}
                          <span style={{ color: "green", cursor: "pointer" }}>
                            (pay now)
                          </span>
                        </a>
                      </>
                    )}
                  </td>
                </tr>
                {Donor.payment?.map((el) => (
                  <tr>
                    <th>
                      Payment ID : <small>{el.transactionID}</small>
                    </th>

                    <td>
                      <b>{el.isVerified===false ? "Under Verification" : "Verified"}</b>{" "}
                      | Paid : <b> ₹{el.amount}/-</b>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            
            <br />
            {/* <div
              data-aos="fade-up"
              style={{ display: "flex", justifyContent: "end" }}
            >
              {Donor.isAdmin && (
                <button
                  onClick={() => (window.location.href = "/admin")}
                  className="btn btn-info w-100"
                >
                  admin console
                </button>
              )}{" "}
              &nbsp;
              <button onClick={handleLogout} className="btn btn-danger w-100">
                Log Out
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Donor