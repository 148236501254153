import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "./Images/new-logo-aahcs-removebg.png";

import axios from "axios";

export const Navbar = () => {
  const [user, setUser] = useState([]);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const authToken = localStorage.getItem("HCAA-TOKEN") || null;
  const donorToken = localStorage.getItem("DONATION-TOKEN") || null;
  const userId = localStorage.getItem("HCAA-USER-ID") || null;
  const [decoded, setDecoded] = useState([]);
  const [notices, setNotices] = useState(0);

  useEffect(() => {
    if (authToken && userId) {
      const fetchUserData = async () => {
        try {
          const response = await axios.get(
            `https://hcaa-backend-v7.onrender.com/v4/user/${userId}`,
            {
              headers: { Authorization: authToken },
            }
          );
          setDecoded(response.data.user.name);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };

      fetchUserData();
    }
  }, []);

  const navigate = useNavigate();
  const handleMobileNavToggle = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  const handleDropdownToggle = (event) => {
    event.preventDefault();
    const dropdown = event.currentTarget.parentElement;
    dropdown.classList.toggle("active");
  };

  const handleSubDropdownToggle = (event) => {
    event.preventDefault();
    const subDropdown = event.currentTarget.parentElement;
    subDropdown.classList.toggle("active");
  };
  const handleSign = () => {
    if (userId && authToken) {
      navigate("/alumni-dashboard");
    } else {
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `https://hcaa-backend-v7.onrender.com/v4/user/${userId}`,
          {
            headers: { Authorization: authToken },
          }
        );
        setUser(response.data.user.alumni);
      } catch (error) {
        console.error("Error fetching user data:", error.message);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://hcaa-backend-v7.onrender.com/v4/notice"
        );
        setNotices(response.data.length);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, [notices]);

  return (
    <>
      <header
        id="header"
        className="fixed-top d-flex align-items-center header-transparent"
      >
        <div className="container d-flex align-items-center justify-content-between">
          <div className="logo" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
            <img src={logo} className="img-fluid animated" alt="logo" />
            <h1>
              <a href="/">
                <span> &nbsp;A A H C S</span>
              </a>
            </h1>
          </div>
          <nav
            id="navbar"
            className={isMobileNavOpen ? "navbar navbar-mobile" : "navbar"}
          >
            <ul>
              <li>
                <a className="nav-link scrollto" href="/about">
                  About
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="/services">
                  Services
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="/gallery">
                  Gallery
                </a>
              </li>
              
                <li>
                  <a className="nav-link scrollto" href="/donation-payment">
                   Donation
                  </a>
                </li>
             
              <li>
                <a className="nav-link scrollto" href="/board-members">
                  Board Members
                </a>
              </li>
              {authToken && (
                <li className="dropdown">
                  <a href="#" onClick={handleDropdownToggle}>
                    <span>Registrations</span>
                    <i className="bi bi-chevron-down" />
                  </a>
                  <ul className={isMobileNavOpen ? "active" : ""}>
                    <li>
                      <a href="/alumni-registration">Event Registration</a>
                    </li>
                    <li>
                        <a href="/membership">LifeTime Membership</a>
                      </li>
                    {/* {user.paymentDetails?.length !== 0 && (
                      <li>
                        <a href="/cultural-program">Cultural Program</a>
                      </li>
                    )}
                    {user.paymentDetails?.length !== 0 && (
                      <li>
                        <a href="/reunion">Reunion</a>
                      </li>
                    )}
                    {user.paymentDetails?.length !== 0 && (
                      <li>
                        <a href="/membership">Membership</a>
                      </li>
                    )} */}
                  </ul>
                </li>
              )}
              <li>
                <a className="nav-link scrollto" href="/notice">
                  Notice
                  <span className="position-absolute top-1 start-100 translate-middle badge rounded-pill bg-danger">
                    {notices}
                  </span>
                </a>
              </li>
              {authToken && (
                <li onClick={handleSign}>
                  <a className="nav-link scrollto" href="">
                    {decoded}
                  </a>
                </li>
              )}
              {!userId && !authToken ? (
                <li>
                  <a className="nav-link scrollto" href="/login">
                    Log In
                  </a>
                </li>
              ) : (
                ""
              )}
            </ul>
            <i
              className="bi bi-list mobile-nav-toggle"
              onClick={handleMobileNavToggle}
            />
          </nav>
          {/* .navbar */}
        </div>
      </header>
    </>
  );
};
