import axios from "axios";
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
} from "../Type/login.type.js";

export const loginRequest = () => ({
  type: LOGIN_REQUEST,
});

export const loginSuccess = () => ({
  type: LOGIN_SUCCESS,
});

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

export const login = (userData) => {
  return (dispatch) => {
    dispatch(loginRequest());
    axios
      .post("https://hcaa-backend-v7.onrender.com/v4/login", userData)
      .then((response) => {
        dispatch(loginSuccess());
        localStorage.setItem("HCAA-TOKEN",response.data.token)
        localStorage.setItem("HCAA-USER-ID",response.data.user._id)
      })
      .catch((error) => {
        dispatch(loginFailure(error.message));
      });
  };
};
