import React from "react";

export const About = () => {
  return (
    <>
      <section id="about" className="about">
        <div className="container mt-5">
          <div className="section-title" data-aos="fade-up">
            <h2>ABOUT</h2>
            <p>ABOUT ALUMNI ASSOCIATION</p>
          </div>
          <div className="row">
            <div
              className="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch"
              data-aos="fade-right"
            >
              <img
                width={"100%"}
                src="https://upload.wikimedia.org/wikipedia/commons/c/c0/SCHOOL.JPG"
                alt=""
              />
            </div>
            <div
              className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5"
              data-aos="fade-left"
            >
              <h3>
                Holy Child School Alumni Association for a Meaningful Journey of
                Connection and Progress .
              </h3>
              <p>
                Alumni Association of Holy Child School Jalpaiguri- We are the
                tribe of all who passed from Holy Child School Jalpaiguri. The
                cult of all who studied in this revered institution. The unified
                voice of all who are from this Alma Mater. An association of
                free will to do good to the society, to stand by the School, to
                support fellow alumni through thick and thin. Bound by
                knowledge, mellowed by nostalgia, galvanised by experience and
                propelled by dreams we are avowed to strive to seek to find and
                not to yield.
              </p>
              <div className="icon-box" data-aos="zoom-in" data-aos-delay={100}>
                <div className="icon">
                  <i className="bx bx-fingerprint" />
                </div>
                <h4 className="title">
                  <a href>Uniting Lives, Inspiring Excellence</a>
                </h4>
                <p className="description">
                  Join us to reconnect with your alma mater, participate in
                  school events, and celebrate the achievements of our esteemed
                  alumni.
                </p>
              </div>
              <div className="icon-box" data-aos="zoom-in" data-aos-delay={200}>
                <div className="icon">
                  <i className="bx bx-gift" />
                </div>
                <h4 className="title">
                  <a href>Connecting Success Stories, Empowering Change</a>
                </h4>
                <p className="description">
                  Proud of the achievements of our alumni across various fields,
                  we foster a vibrant community that embraces excellence and
                  creates meaningful impacts.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
