import React, { useState, useEffect } from "react";
import axios from "axios";
import { checkoutHandler } from "../Api/payment.api.js";

const Reunion = () => {
  const authToken = localStorage.getItem("HCAA-TOKEN") || null;
  const userId = localStorage.getItem("HCAA-USER-ID") || null;
  const [decoded, setDecoded] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `https://hcaa-backend-v7.onrender.com/v4/user/${userId}`,
          {
            headers: { Authorization: authToken },
          }
        );
        setDecoded(response.data.user);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <section id="gallery" className="gallery">
      <div className="container mt-5">
        <div className="section-title" data-aos="fade-up">
          <h2>Reunion</h2>
          <p>Reunion REGISTRATION</p>
          {authToken && <h4>Welcome {decoded.name} </h4>}
        </div>
        <div className="container">
          <h4>
            The Holy Child Alumni Association Reunion is a much-anticipated
            event that brings together former students of Holy Child School.
            This special gathering provides an opportunity for alumni to
            reconnect with old friends, reminisce about their school days, and
            create new memories together.
          </h4>
          <br />
          <p>
            <mark>
              {" "}
              Cost: ₹ 1000/- per person Inclusions: The reunion registration fee
              includes access to all event activities and a delicious lunch.
            </mark>
          </p>
        </div>
        <div data-aos="fade-up">
          {decoded.alumni?.length !== 0 ? (
            <button
              disabled={decoded.alumni?.length == 0}
              onClick={async () =>
                await checkoutHandler(1000, authToken, "Reunion")
              }
              className="btn btn-success w-100"
            >
              ₹ 1000
            </button>
          ) : (
            <div class="alert alert-info" role="alert">
              You need to become a member first
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Reunion;
