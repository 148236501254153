import React, { useState, useEffect } from "react";
import axios from "axios";

const AlumniCard = () => {
  const authToken = localStorage.getItem("HCAA-TOKEN") || null;
  const userId = localStorage.getItem("HCAA-USER-ID") || null;
  const [solo, setSolo] = useState(JSON.parse(localStorage.getItem("ALUMNI")));
  const [decoded, setDecoded] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `https://hcaa-backend-v7.onrender.com/v4/user/${userId}`,
          {
            headers: { Authorization: authToken },
          }
        );
        setDecoded(response.data.user);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchUserData();
  }, []);


  if (solo.batch === 'ISC') {
    setSolo((prevSolo) => ({
      ...prevSolo,
      batch: 'ICSE',
      year: prevSolo.year - 2,
    }));
  }

  return (
    <section id="gallery" className="gallery">
      <div className="container mt-6">
        <div className="section-title" data-aos="fade-up">
          <h2>Alumni Details</h2>
          <p>ALUMNI DETAILS</p>
          {authToken && <h4>Welcome ADMIN </h4>}
        </div>
        <div className="row">
          <div key={solo._id} className="col-md-12">
            <div className="card mb-4">
              <div className="card-body">
                <h5 className="card-title">{solo.name}</h5>
                <p className="card-text">
                  <strong>Email:</strong> {solo.emailAddress}
                  <br />
                  <strong>ID:</strong> {solo._id}
                  <br />
                  <strong>PermanentAddress :</strong> {solo.permanentAddress}
                  <br />
                  <strong>Gender:</strong> {solo.gender}
                  <br />
                  <strong>Spouse:</strong> {solo.spouse ? solo.spouse : "N/A"}
                  {/*<br />
                   <strong>highest Qualification:</strong>{" "}
                  {solo.highestQualification} */}
                  {/* <br />
                  <strong>Present Address:</strong> {solo.presentAddress}
                  <br />
                  <strong>Present Occupation:</strong> {solo.presentOccupation}
                  */}
                  <br />
                  <strong>Passout Year:</strong> {solo.year}
                  <br />
                  <strong>Batch:</strong> {solo.batch}
                  {/* <br />
                  <strong>Blood Group:</strong> {solo.bloodGroup}
                  <br />
                  <strong>T-Shirt Size:</strong> {solo.shirtSize} */}
                  <br />
                  <strong>Food Preference:</strong> {solo.food}
                  <br />
                  <strong>Contact Number:</strong>{" "}{solo.phoneNumber.toString().length > 10 ?`+${solo.phoneNumber}`: solo.phoneNumber   }
                </p>
                <hr />
                {solo.paymentDetails.length !== 0 ? (
                  <div className="docs-images">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th scope="col">Payment ID</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Date</th>
                          {/* <th scope="col">Date</th> */}
                          {/* <th scope="col">Event Type</th> */}
                          <th scope="col">Verified</th>
                        </tr>
                      </thead>
                      <tbody>
                        {solo.paymentDetails.map((el) => (
                          <tr>
                            <th scope="row">{el.transactionID}</th>
                            <td> ₹ {el.amount}/-</td>
                            <td>{el.date}</td>
                            {/* <td>{new Date(el.date).toLocaleDateString()}</td> */}
                            {/* <td>{el.eventType}</td> */}
                            <td>{el.isVerified === false ? "Under Verification" : "Verified"}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p>NO PAYMENTS DONE YET</p>
                )}
                {/* <div className="docs-images">
                  <div>
                    <h5>Adhar Card</h5>
                    <img
                      className="card-img-top"
                      src={`https://hcaa-backend-v7.onrender.com/${solo.adharCard}`}
                      alt={solo.name}
                    />
                  </div>
                  <div>
                    <h5>Visiting Card</h5>
                    <img
                      className="card-img-top"
                      src={`https://hcaa-backend-v7.onrender.com/${solo.visitingCard}`}
                      alt={solo.name}
                    />
                  </div>
                  <div>
                    <h5>Voter Card</h5>
                    <img
                      className="card-img-top adhar"
                      src={`https://hcaa-backend-v7.onrender.com/${solo.voterCard}`}
                      alt={solo.name}
                    />
                  </div>
                  <div>
                    <h5>OfficeID Card</h5>
                    <img
                      className="card-img-top adhar"
                      src={`https://hcaa-backend-v7.onrender.com/${solo.officeId}`}
                      alt={solo.name}
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AlumniCard;
