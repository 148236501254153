import { useState } from "react";
import qr from "./More/aahcs-pay.jpeg";
import jwt_decode from "jwt-decode";
import axios from "axios";

export default function TempPayment() {
  //const authToken = localStorage.getItem("DONATION-TOKEN") || null
  // const authToken = localStorage.getItem("HCAA-TOKEN") || null;
  // var decodedJwt = jwt_decode(authToken);
  // const [isLoading, setIsloading] = useState(false);
  // const [donation, setDonation] = useState({
  //   transactionID: "",
  //   phoneNumber: "" || decodedJwt.phoneNumber,
  //   id: "" || decodedJwt.donationId,
  //   amount: "",
  // });

  // const handleInput = (e) => {
  //   const { name, value } = e.target;
  //   setDonation({ ...donation, [name]: value });
  // };

  // const handleUpload = async (e) => {
  //   e.preventDefault();
  //   try {
  //     setIsloading(true);
  //     const response = await axios.post(
  //       "http://127.0.0.1:8801/v4/donation-payment",
  //       donation,
  //       {
  //         headers: {
  //           Authorization: authToken,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     if (response.status === 201) {
  //       setIsloading(false);
  //       alert("uploaded successfully");
  //       setTimeout(() => {
  //         window.location.href = "/donor";
  //       }, 800);
  //     } else {
  //       setIsloading(false);
  //       alert("Failed to donate.");
  //     }
  //   } catch (error) {
  //     setIsloading(false);
  //     console.log(error);
  //   }
  // };

  return (
    <section id="gallery" className="gallery">
      <div className="container mt-5">
        <div data-aos="fade-up ">
        <div className="section-title" data-aos="fade-up">
            <h2>donation</h2>
            <p>DONATION</p>
          </div>
          <hr />
          <p>
            <div className="alert alert-primary" role="alert">
              we kindly request that, upon the successful completion of your
              donation,{" "}
              <b>
                {" "}
                you capture a screenshot of the payment confirmation on your
                device or collect bank receipt as Payment proof . Your financial contribution is invaluable to us, and
                having a record of your transaction will aid in our internal
                processes.
              </b>{" "}
              Please take a moment to share this screenshot with the designated
              person in charge.
            </div>
            {/* We want to Thank <b>{decodedJwt?.name}</b> that while{" "}
            <mark>
              {" "}
              you've successfully completed your Donation registration, we've
              yet to receive your payment.{" "}
            </mark>{" "} */}
            <br />
            <br />
            <div className="card mb-3">
              <div className="row g-0">
                <div className="col-md-4">
                  <img src={qr} className="img-fluid rounded-start" alt="..." />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <p className="card-text" style={{textAlign:"center"}}>
                     
                      <b>BANK Details : </b>
                      <hr />
                      <br />
                      AC no : 921010041186481
                      <br />
                      Alumni Association of Holy Child School Jalpaiguri
                      <br />
                      Axis Bank Dinbazar Jalpaiguri Branch
                      <br />
                      IFSC : UTIB0004284
                      <br />
                      
                    </p>
                    <div className="card p-4">
                      <b>NOTE :</b> There is no option of cancellation and
                      refund. In case of any query please contact through e
                      mail.
                      <br />
                      Contact : alumnihcsjalpaiguri@gmail.com
                      <br />
                      Mob : 8116123456 (whatsapp)
                      <br />
                      Address : Hakimpara Jalpaiguri 735101
                    </div>
                  </div>
                </div>
              </div>
            </div>
            This additional step will not only assist us in maintaining accurate
            records but will also serve as a tangible acknowledgment of your
            dedicated support. Thank you once again for your generosity.
            Together, we can continue to make a meaningful difference in the
            lives of those we aim to serve.
          </p>
          {/* <b style={{ color: "red" }}>
            To ensure the accuracy and security of our records, we kindly
            request that you upload your Transaction ID for verification.{" "}
          </b> */}
          {/* <form onSubmit={handleUpload} style={{ marginTop: "-6rem" }}>
            <label htmlFor="transactionID">Transaction ID</label>
            <input
              onChange={handleInput}
              name="transactionID"
              value={donation.transactionID}
              type="text"
              className="form-control"
              placeholder="Enter Transaction ID"
              required
            />

            <label htmlFor="alumniPhoneNumber">Phone Number</label>
            <input
              name="phoneNumber"
              value={decodedJwt.phoneNumber}
              onChange={handleInput}
              type="number"
              className="form-control"
              readOnly
              disabled
            />
            <label htmlFor="idr">User ID</label>
            <input
              name="id"
              value={decodedJwt.donationId}
              onChange={handleInput}
              type="text"
              className="form-control"
              readOnly
              disabled
            />

            <label htmlFor="amount">Amount Donated</label>
            <input
             id="amount"
              name="amount"
              value={donation.amount}
              type="number"
              className="form-control"
              placeholder="Enter Donation Amount"
              onChange={handleInput}
            />
            <br />
            <input
              className="btn btn-success w-100"
              value={!!isLoading ? "Submitting..." : "Submit"}
              type="submit"
              disabled={isLoading}
            />
          </form> */}
        </div>
      </div>
    </section>
  );
}
