import {LOGIN_REQUEST,LOGIN_SUCCESS,LOGIN_FAILURE} from "../Type/login.type.js"

const initialState = {
    isLoading : false,
    isSuccess: false,
    error:null
}

export const loginReducer = (state= initialState, action)=>{
    switch (action.type) {
        case LOGIN_REQUEST:
          return {
            ...state,
            isLoading: true,
            isSuccess: false,
            error: null,
          };
        case LOGIN_SUCCESS:
          return {
            ...state,
            isLoading: false,
            isSuccess: true,
            error: null,
          };
        case LOGIN_FAILURE:
          return {
            ...state,
            isLoading: false,
            isSuccess: false,
            error: action.payload,
          };
        default:
          return state;
      }
}