import React from "react";

const StopSignup = () => {
  return (
    <section id="gallery" className="gallery">
      <div className="container mt-5">
        Dear students,
        <br />
        <br />
        Effective immediately, new user sign-ups have been temporarily paused. Moving forward, only those who signed up before the 28th of November will be able to <a href="/login">log in</a> and access AAHCS.
        <br />
        <br />
        <b>
          28th November was the last day of online registration … Further
          registration will start on school premise from 2nd December 9 AM .{" "}
        </b>
        <br />
        You can do offline and online registration on that day too .{" "}
        <b>
          There will be special registration on 29th Nov , 30th Nov and 1st
          December only through batch coordinator .
        </b>{" "}
        Please contact them directly on those days…
        <br />
        <br /> Regards AAHCS
      </div>
    </section>
  );
};

export default StopSignup;
