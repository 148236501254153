import React from "react";

export const Gallery = () => {
  const imageUrls = [
    "https://holychildjalpaiguri.in/wp-content/uploads/2020/09/IMG-20200815-WA0010-99999x500.jpg",
    "https://holychildjalpaiguri.in/wp-content/uploads/2020/09/IMG-20200815-WA0038-500x99999.jpg",
    "https://holychildjalpaiguri.in/wp-content/uploads/2020/09/IMG-20200815-WA0044-500x99999.jpg",
    "https://holychildjalpaiguri.in/wp-content/uploads/2020/09/IMG-20200815-WA0012-500x99999.jpg",
    "https://holychildjalpaiguri.in/wp-content/uploads/2020/09/IMG-20200815-WA0030-99999x500.jpg",
    "https://holychildjalpaiguri.in/wp-content/uploads/2020/09/IMG-20200815-WA0032-99999x500.jpg",
    "https://holychildjalpaiguri.in/wp-content/uploads/2020/09/IMG-20200815-WA0047-500x99999.jpg",
    "https://holychildjalpaiguri.in/wp-content/uploads/2020/09/IMG-20200815-WA0043-99999x500.jpg",
  ];

  return (
    <>
      <section id="gallery" className="gallery">
        <div className="container alumni-gallery mt-5">
          <div className="section-title" data-aos="fade-up">
            <h2>Gallery</h2>
            <p>ALUMNI Gallery</p>
          </div>
          <div className="row g-0" data-aos="fade-left">
            {imageUrls.map((imageUrl, index) => (
              <div className="col-lg-3 col-md-4" key={index}>
                <div
                  className="gallery-item"
                  data-aos="zoom-in"
                  data-aos-delay={100 + 50 * index}
                >
                  <a href={imageUrl} className="gallery-lightbox">
                    <img
                      src={imageUrl}
                      alt={`Gallery ${index + 1}`}
                      className="img-fluid"
                    />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};
