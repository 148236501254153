import React, { useState, useEffect } from "react";
import { checkoutHandler } from "../Api/payment.api.js";
import qr from "./aahcs-pay.jpeg";
import axios from "axios";

export const Membership = () => {
  const authToken = localStorage.getItem("HCAA-TOKEN") || null;
  const userId = localStorage.getItem("HCAA-USER-ID") || null;
  const [alumni, setAlumni] = useState([]);
  const [payment, setPayment] = useState([]);
  const [decoded, setDecoded] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `https://hcaa-backend-v7.onrender.com/v4/user/${userId}`,
          {
            headers: { Authorization: authToken },
          }
        );
        setDecoded(response.data.user);
        if (decoded) {
          setAlumni(response.data.user.alumni);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    if (decoded) {
      const fetchUserData = async () => {
        try {
          const response = await axios.get(
            `https://hcaa-backend-v7.onrender.com/v4/alumni/${alumni[0]._id.toString()}`,
            {
              headers: { Authorization: authToken },
            }
          );
          setPayment(response.data.paymentDetails);
        } catch (error) {
          console.error("Error fetching user data");
        }
      };

      fetchUserData();
    }
  }, [alumni]);

  const bool = payment.filter((el) => el.eventType === "Life Time Membership");

  return (
    <div>
      <section id="gallery" className="gallery">
        <div className="container mt-5">
          <div className="section-title" data-aos="fade-up">
            <h2>Membership</h2>
            <p>Life Time Membership</p>
            {authToken && <h4>Welcome {decoded.name} </h4>}
          </div>
          <div className="container">
            <div className="alert alert-warning" role="alert">
              <h4>
                Pay <b>Rs 25,000 </b> and become the lifetime member of AAHCS
                Jalpaiguri.
              </h4>
            </div>
            <p>
              We are thrilled to introduce our exclusive Lifetime Membership
              option, designed to offer you an unparalleled connection to your
              alma mater and fellow alumni. By becoming a Lifetime Member of the
              AAHCS, you'll enjoy a host of benefits and the opportunity to
              cherish your time at AAHCS for a lifetime.
            </p>
            <br />
            <div className="card mb-3">
              <div className="row g-0">
                <div className="col-md-4">
                  <img src={qr} className="img-fluid rounded-start" alt="..." />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <p className="card-text" style={{ textAlign: "center" }}>
                      <b>BANK Details : </b>
                      <hr />
                      <br />
                      AC no : 921010041186481
                      <br />
                      Alumni Association of Holy Child School Jalpaiguri
                      <br />
                      Axis Bank Dinbazar Jalpaiguri Branch
                      <br />
                      IFSC : UTIB0004284
                      <br />
                    </p>
                    <div className="card p-4">
                      <b>NOTE :</b> There is no option of cancellation and
                      refund. In case of any query please contact through e
                      mail.
                      <br />
                      Contact : alumnihcsjalpaiguri@gmail.com
                      <br />
                      Mob : 8116123456 (whatsapp)
                      <br />
                      Address : Hakimpara Jalpaiguri 735101
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="alert alert-info" role="alert">
            we kindly request that, upon the successful completion of your
            donation,{" "}
            <b>
              {" "}
              you capture a screenshot of the payment confirmation on your
              device or collect bank receipt as Payment proof . Your financial
              contribution is invaluable to us, and having a record of your
              transaction will aid in our internal processes.
            </b>{" "}
            Please take a moment to share this screenshot with the designated
            person in charge.
          </div>
          {/* <div data-aos="fade-up">
            {bool?.length == 0 ? (
              <button
                disabled={decoded.alumni?.length == 0 || 0}
                onClick={async () =>
                  await checkoutHandler(
                    10000,
                    authToken,
                    "Life Time Membership"
                  )
                }
                className="btn btn-success w-100"
              >
                {decoded.alumni?.length !== 0
                  ? "Join Lifetime Membership for ₹ 10,000 /-"
                  : "You Need to Become a Member First"}
              </button>
            ) : (
              <div className="card p-4">
                <p>
                  You have <b>Successfully Registered as Life Time Member</b>{" "}
                  with payment Id : {bool[0].razorpay_payment_id}
                </p>
              </div>
            )}
          </div> */}
        </div>
      </section>
    </div>
  );
};
