import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "./Images/new-logo-aahcs-removebg.png";
import EventCarousal from "./EventCarousal";
import { useState } from "react";
import { useEffect } from "react";

export const Hero = () => {
  const authToken = localStorage.getItem("HCAA-TOKEN") || null;
  const donorToken = localStorage.getItem("DONATION-TOKEN") || null;
  const navigate = useNavigate();

  const handleRegistration = () => {
    if (authToken) {
      navigate("/alumni-registration");
    } else {
      navigate("/login");
    }
  };
  // const handleDonation =()=>{
  //   if(donorToken){
  //     navigate("/donation-payment")
  //   }
  //   else{
  //     navigate("/donation")
  //   }
  // }
  const handleDonation = () => {
    navigate("/donation-payment");
  };

  //----------------------------------------------------



  //----------------------------------------------------

  return (
    <>
    
      <section id="hero">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-7 pt-5 pt-lg-0 order-2 order-lg-1 d-flex align-items-center">
              <div data-aos="zoom-out">
                <h1>
                  Alumni Association <br /> Holy Child School , Jalpaiguri{" "}
                  <span>
                    {" "}
                    <i>
                      {" "}
                      <br /> " A A H C S "
                    </i>{" "}
                  </span>
                </h1>
                <br />
                <button
                  className="btn btn-success"
                  onClick={handleRegistration}
                >
                  Event Registration
                </button>{" "}
                <button className="btn btn-warning" onClick={handleDonation}>
                  Donation
                </button>
              </div>
            </div>
            <div
              className="col-lg-4 order-1 order-lg-2 hero-img"
              data-aos="zoom-out"
              data-aos-delay={300}
            >
              {/* <EventCarousal /> */}
              <img src={logo} className="img-fluid animated" alt="" />
            </div>
          </div>
        </div>
        <svg
          className="hero-waves"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28 "
          preserveAspectRatio="none"
        >
          <defs>
            <path
              id="wave-path"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            ></path>
          </defs>
          <g className="wave1">
            <use
              xlinkHref="#wave-path"
              x={50}
              y={3}
              fill="rgba(255,255,255, .1)"
            ></use>
          </g>
          <g className="wave2">
            <use
              xlinkHref="#wave-path"
              x={50}
              y={0}
              fill="rgba(255,255,255, .2)"
            ></use>
          </g>
          <g className="wave3">
            <use xlinkHref="#wave-path" x={50} y={9} fill="#fff"></use>
          </g>
        </svg>
      </section>
    </>
  );
};



