import React from "react";

const Refund = () => {
  return (
    <div>
      <p>
        <b>Refund Policy: </b>
        There is no option of cancellation and refund. In case of any query
        please contact through e mail.
        <br />
        Contact : alumnihcsjalpaiguri@gmail.com
        <br />
        Mobile : 8116123456 (whatsapp)
        <br />
        Address : Hakimpara,Jalpaiguri-735101
      </p>
    </div>
  );
};

export default Refund;
