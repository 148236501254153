import React from 'react'

const Terms = () => {
  return (
    <div>
      <p>
        <b>Terms & Conditions : </b>
         Non refundable donative payments as membership of the Association or as registration for events of the association.</p>
    </div>
  )
}

export default Terms