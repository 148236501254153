import React from "react";
import axios from "axios";

const AlumniExportButton = () => {
  const authToken = localStorage.getItem("HCAA-TOKEN") || null;
  const handleExportClick = async () => {
    try {
      const response = await axios.get(
        "https://hcaa-backend-v7.onrender.com/v4/exportAlumniToExcel",
        {
          responseType: "blob",
          headers: {
            Authorization: authToken,
          },
        }
      );
  
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "alumni.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Export failed:", error.message);
    }
  };

  return <button className="btn btn-light w-100" onClick={handleExportClick}>Export Alumni to Excel</button>;
};

export default AlumniExportButton;
