import React, { useState } from "react";
import axios from "axios";

const PostNoticeForm = () => {
  const authToken = localStorage.getItem("HCAA-TOKEN") || null;
  const [formData, setFormData] = useState({
    title: "",
    venue: "",
    description: "",
    url:"",
    timing: "",
  });
  const [isLoading, setIsloading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsloading(true);
      const response = await axios.post(
        "https://hcaa-backend-v7.onrender.com/v4/notice",
        formData,
        {
          headers: {
            Authorization: authToken,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 201) {
        setIsloading(false);
        alert("Notice created successfully");
        setFormData({
          title: "",
          venue: "",
          description: "",
          url:"",
          timing: "",
        });
      } else {
        setIsloading(false);
        alert("Failed to create notice.");
     }
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };



  return (
    <section id="gallery" className="gallery">
      <div className="container mt-5">
        <div className="section-title" data-aos="fade-up">
          <h2>Notice</h2>
          <p>Create Notice</p>
          {authToken && <h4>ADMIN</h4>}
        </div>
        <form
          className="notice-form"
          onSubmit={handleSubmit}
          style={{ marginTop: "-8rem" }}
        >
          <div className="mb-3">
            <label htmlFor="title" className="form-label">
              Event Title
            </label>
            <input
              type="text"
              className="form-control"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="venue" className="form-label">
              Venue
            </label>
            <input
              type="text"
              className="form-control"
              id="venue"
              name="venue"
              value={formData.venue}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="description" className="form-label">
              Description
            </label>
            <textarea
              className="form-control"
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <div className="mb-3">
            <label htmlFor="timing" className="form-label">
              url
            </label>
            <input
              type="url"
              className="form-control"
              id="url"
              name="url"
              value={formData.url}
              onChange={handleChange}
            />
            <br />
             <label htmlFor="timing" className="form-label">
              Timing
            </label>
             <input
              type="datetime-local"
              className="form-control"
              id="timing"
              name="timing"
              value={formData.timing}
              onChange={handleChange}
            />
          </div>
          <button
            disabled={isLoading}
            type="submit"
            className="btn btn-primary w-100"
          >
            {isLoading ? "Posting..." : "Post Notice"}
          </button>
        </form>
      </div>
    </section>
  );
};

export default PostNoticeForm;
