import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import signupReducer from "./Redux/Reducer/signup.reducer.js";
import { loginReducer } from "./Redux/Reducer/login.reducer.js";
import alumniReducer from "./Redux/Reducer/alumni.reducer.js";


const rootReducer = combineReducers({
  signup: signupReducer,
  login: loginReducer,
  alumni: alumniReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
