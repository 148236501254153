import React from "react";

export const Contact = () => {
  return (
    <>
      <section id="contact" className="contact">
        <div className="container mt-5">
          <div className="section-title" data-aos="fade-up">
            <h2>Contact</h2>
            <p>Contact Us</p>
          </div>
          <div class="col-md-12" style={{ marginBottom: "20px" }}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.8454365015077!2d88.7241900150712!3d26.529253083298984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e40dc7a6c4b249%3A0x23ad5450fb3a2e3f!2sHoly%20Child%20School!5e0!3m2!1sen!2sin!4v1625414249073!5m2!1sen!2sin"
              width="100%"
              height="300"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
          <div className="row">
            <div
              className="col-lg-4"
              data-aos="fade-right"
              data-aos-delay={100}
            >
              <div className="info">
                <div className="address">
                  <i className="bi bi-geo-alt" />
                  <h4>Location:</h4>
                  <p>Mohitnagar , Jalpaiguri , 735101 , WB</p>
                </div>
                <div className="email">
                  <i className="bi bi-envelope" />
                  <h4>Email:</h4>
                  <p>holychildjalpaiguri@gmail.com</p>
                </div>
                <div className="phone">
                  <i className="bi bi-phone" />
                  <h4>Call:</h4>
                  <p>+91 7362979436</p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-8 mt-5 mt-lg-0"
              data-aos="fade-left"
              data-aos-delay={200}
            >
              {/* <form style={{ border: "1px solid" }}>
                <div className="row" >
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      placeholder="Your Name"
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Your Email"
                      required
                    />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    id="subject"
                    placeholder="Subject"
                    required
                  />
                </div>
                <div className="form-group mt-3">
                  <textarea
                    className="form-control"
                    name="message"
                    rows={5}
                    placeholder="Message"
                    required
                    defaultValue={""}
                  />
                </div>
                <div className="my-3">
                  <div className="loading">Loading</div>
                  <div className="error-message" />
                  <div className="sent-message">
                    Your message has been sent. Thank you!
                  </div>
                </div>
                <div className="text-center">
                  <button type="submit">Send Message</button>
                </div>
              </form> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
