import React from "react";

export const Features = () => {
  return (
    <>
      <section id="features" className="features">
        <div className="container mt-5">
          <div className="section-title" data-aos="fade-up">
            <h2>Social Works</h2>
            <p>Volunteer activities & initiatives.</p>
          </div>
          <div className="row" data-aos="fade-left">
            <div className="col-lg-3 col-md-4 mt-4">
              <div className="icon-box" data-aos="zoom-in" data-aos-delay={250}>
                <i
                  className="ri-database-2-line"
                  style={{ color: "#47aeff" }}
                />
                <h3>
                  <a href>Community Outreach</a>
                </h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mt-4">
              <div className="icon-box" data-aos="zoom-in" data-aos-delay={300}>
                <i
                  className="ri-gradienter-line"
                  style={{ color: "#ffa76e" }}
                />
                <h3>
                  <a href>Educational Initiatives</a>
                </h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mt-4">
              <div className="icon-box" data-aos="zoom-in" data-aos-delay={350}>
                <i
                  className="ri-file-list-3-line"
                  style={{ color: "#11dbcf" }}
                />
                <h3>
                  <a href>Environmental Conservation</a>
                </h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mt-4">
              <div className="icon-box" data-aos="zoom-in" data-aos-delay={400}>
                <i
                  className="ri-price-tag-2-line"
                  style={{ color: "#4233ff" }}
                />
                <h3>
                  <a href>Health and Wellness</a>
                </h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mt-4">
              <div className="icon-box" data-aos="zoom-in" data-aos-delay={450}>
                <i className="ri-anchor-line" style={{ color: "#b2904f" }} />
                <h3>
                  <a href>Skill Development </a>
                </h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mt-4">
              <div className="icon-box" data-aos="zoom-in" data-aos-delay={500}>
                <i className="ri-disc-line" style={{ color: "#b20969" }} />
                <h3>
                  <a href>Philanthropic Endeavors</a>
                </h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mt-4">
              <div className="icon-box" data-aos="zoom-in" data-aos-delay={550}>
                <i
                  className="ri-base-station-line"
                  style={{ color: "#ff5828" }}
                />
                <h3>
                  <a href>Alumni Mentorship</a>
                </h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mt-4">
              <div className="icon-box" data-aos="zoom-in" data-aos-delay={600}>
                <i
                  className="ri-fingerprint-line"
                  style={{ color: "#29cc61" }}
                />
                <h3>
                  <a href>Cultural and Arts Initiatives</a>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ======= Details Section ======= */}
      <section id="details" className="details">
        <div className="container">
          <div className="row content" style={{ margin: "-70px 0 -50px 0" }}>
            <div className="col-md-4" data-aos="fade-right">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/c/c0/SCHOOL.JPG"
                className="img-fluid"
                alt=""
                style={{ marginTop: "35px" }}
              />
            </div>
            <div className="col-md-8 pt-4" data-aos="fade-up">
              <h3>
                Making a Difference Through Volunteer Initiatives: Empowering
                Communities, Inspiring Change.
              </h3>
              <p className="fst-italic">
                Discover the significance of engaging in volunteer activities
                and initiatives, as the AAHCS takes the lead in empowering
                communities and igniting transformative change.
              </p>
              <ul>
                <li>
                  <i className="bi bi-check" /> creating a powerful network
                  united by the shared goal of giving back.
                </li>
                <li>
                  <i className="bi bi-check" /> AAHCS members contribute to
                  alleviating social issues and making a positive impact on
                  society.
                </li>
                <li>
                  <i className="bi bi-check" /> mentorship programs and
                  educational initiatives helps shape the next generation
                </li>
                <li>
                  <i className="bi bi-check" /> skill enhancement, and the
                  development of a compassionate and service-oriented mindset.
                </li>
              </ul>
            </div>
          </div>
          <div className="row content">
            <div className="col-md-4 order-1 order-md-2" data-aos="fade-left">
              <img
                src="https://www.joonsquare.com/usermanage/image/business/holy-child-school-jalpaiguri-12444/holy-child-school-jalpaiguri-holy-child-school-jalpaiguri-2.jpg"
                className="img-fluid"
                alt=""
              />
            </div>
            <div
              className="col-md-8 pt-5 order-2 order-md-1"
              data-aos="fade-up"
            >
              <h3>
                Empowering Change through AAHCS Volunteer Initiatives: Igniting
                Impact, Creating a Better Future .
              </h3>
              <p className="fst-italic">
                Community Engagement: AAHCS's volunteer initiatives actively
                involve alumni in community service, enabling them to connect,
                contribute, and make a lasting impact on society.
              </p>
              <p>
                Discover the significance of AAHCS's commitment to volunteer
                initiatives and how these endeavors play a vital role in
                empowering individuals, fostering positive change, and shaping a
                brighter future.By participating in these initiatives, AAHCS
                inspires hope, provides support, and improves the lives of
                individuals and communities, ultimately creating a ripple effect
                of positive change.
              </p>
              <p>
                Through volunteer work, AAHCS members develop essential skills,
                broaden their perspectives, and cultivate a sense of empathy and
                compassion, fostering personal growth and well-rounded
                individuals.
              </p>
            </div>
          </div>
          <div style={{ marginTop: "20px" }} className="row content">
            <div className="col-md-4" data-aos="fade-right">
              <img
                src="https://holychildjalpaiguri.in/wp-content/uploads/2020/09/IMG-20200815-WA0047.jpg"
                className="img-fluid"
                alt="image3"
                style={{ marginTop: "35px" }}
              />
            </div>
            <div className="col-md-8 pt-5" data-aos="fade-up">
              <h3>
                Transforming Lives and Upholding the Significance of Volunteer
                Initiatives
              </h3>
              <p>
          AAHCS has successfully implemented a
                range of volunteer initiatives, positively impacting communities
                through educational programs, health campaigns, environmental
                conservation efforts, and more, fostering empowerment and
                sustainable development.
              </p>

              <p>
                Through their philanthropic endeavors and
                mentorship programs, AAHCS has touched countless lives,
                providing scholarships, support to the underprivileged, and
                invaluable guidance to inspire personal and professional growth.
              </p>
              <p>
                AAHCS's commitment to volunteer
                initiatives sets a powerful example for future alumni,
                encouraging them to continue the legacy of service, compassion,
                and transformative change, ensuring a brighter future for all.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* End Details Section */}
    </>
  );
};
