import React from 'react'
import Refund from './Refund'
import Terms from './Terms'
import Shipping from './Shipping'

const Privacy = () => {
  return (
    <section id="gallery" className="gallery">
    <div className='container mt-5'>
      <p>
        <b>Privacy & Policy : </b>
        payment details, amount and other personal data will not be shared without permission of member. Statement of accounts etc will be placed in Annual General Meetings.</p>
        <Terms/>
        <Refund/>
        <Shipping/>
    </div>
    </section>
  )
}

export default Privacy