import {
  REGISTER_ALUMNI_REQUEST,
  REGISTER_ALUMNI_SUCCESS,
  REGISTER_ALUMNI_FAILURE,
} from "../Type/alumni.type.js";

const initialState = {
  isLoading: false,
  isRegistrationFeeStatus: false,
  error: null,
};

const alumniReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_ALUMNI_REQUEST:
      return { ...state, isLoading: true, error: null };
    case REGISTER_ALUMNI_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isRegistrationFeeStatus: action.payload,
        error: null,
      };
    case REGISTER_ALUMNI_FAILURE:
      return {
        ...state,
        isLoading: false,
        isRegistrationFeeStatus: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default alumniReducer;
