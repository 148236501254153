import axios from "axios";

export const checkoutHandler = async (amount, authToken, eventType) => {
  const headers = {
    Authorization: `${authToken}`,
  };

  try {
    const verificationData = {
      amount,
      authToken,
    };

    const {
      data: { key },
    } = await axios.get("https://hcaa-backend-v7.onrender.com/v4/getkey", {
      headers,
    });
    const {
      data: { order },
    } = await axios.post(
      "https://hcaa-backend-v7.onrender.com/v4/checkout",
      verificationData,
      { headers }
    );

    const options = {
      key,
      amount: order.amount,
      currency: "INR",
      name: "Holy Child Alumni Association",
      description: "payments page",
      image:
        "https://holychildjalpaiguri.in/wp-content/uploads/2020/08/New-Logo-1-1-697x710.png",
      order_id: order.id,
      callback_url: `https://hcaa-backend-v7.onrender.com/v4/verification?authToken=${authToken}&amount=${amount}&eventType=${eventType}`,
      prefill: {
        name: "HolyChild Alumni Association",
        email: "holychildjalpaiguri@gmail.com",
        contact: "+91 7362979436",
      },
      notes: {
        address: "Jalpaiguri, WB",
      },
      theme: {
        color: "#121212",
      },
    };
    const razor = new window.Razorpay(options);
    return razor.open();
  } catch (error) {
    console.error("Error in checkoutHandler:", error);
    throw error;
  }
};
