import React, { useState, useEffect } from "react";
import axios from "axios";

const Payments = ({ props }) => {
  const authToken = localStorage.getItem("HCAA-TOKEN") || null;
  const [paymentData, setPaymentData] = useState([]);
  const [filterPaymentData, setFilterPaymentData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://hcaa-backend-v7.onrender.com/v4/payments", {
          headers: {
            Authorization: authToken,
          },
        });
        setPaymentData(response.data);
        setFilterPaymentData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, [authToken, props]);

  console.log(paymentData);

  const numbers = paymentData?.map((el) => +el.amount);
  const total = numbers.reduce((acc, val) => acc + val, 0);

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    const filteredData = paymentData.filter((el) =>
      el.eventType.toLowerCase().includes(searchTerm)
    );
    setFilterPaymentData(filteredData);
  };

  return (
    <div className="admin-lower-2">
      <h3>
        Total Collection ₹ {total} /- Rupees as per :{" "}
        {new Date().toLocaleDateString()}
      </h3>
      <p>
        Total <b> {filterPaymentData.length}</b> Payments Done Yet.{" "}
      </p>
      <input
        type="text"
        placeholder="Search Event Type Here"
        onChange={handleSearch}
        className="form-control"
      />
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Order ID</th>
            <th scope="col">Payment ID</th>
            <th scope="col">Payment Date</th>
            <th scope="col">Payment Time</th>
            <th scope="col">Event Type</th>
            <th scope="col">Amount</th>
          </tr>
        </thead>
        <tbody>
          {filterPaymentData.map((el) => (
            <tr key={el._id}>
              <td>{el._id}</td>
              <td>{el.razorpay_order_id}</td>
              <td>{el.razorpay_payment_id}</td>
              <td>{new Date(el.createdAt).toLocaleDateString()}</td>
              <td>{new Date(el.createdAt).toLocaleTimeString()} </td>
              <td>{el.eventType}</td>
              <td>{el.amount}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Payments;
