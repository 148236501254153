import React, { useEffect, useState } from "react";
import logo from "./Images/new-logo-aahcs-removebg.png";
import { Hero } from "./Hero";
import { About } from "./About";
import { Features } from "./Features";
import { Gallery } from "./Gallery";
import { Testimonial } from "./Testimonial";
import { Team } from "./Team";
import { Faq } from "./Faq";
import { Contact } from "./ContactUs";

export const Home = () => {
  const [showPopup, setShowPopup] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowPopup(false);
    }, 50000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      <div>
        {/* ======= Hero Section ======= */}
        <Hero />
        {/* End Hero */}
        <main id="main">
          {/* {showPopup && <Popup onClose={() => setShowPopup(false)} />} */}
          {/* <Popup/> */}
          {/* ======= About Section ======= */}
          <About />
          {/* End About Section */}
          {/* ======= Features Section ======= */}
          <Features />
          {/* End Features Section */}
          {/* ======= Gallery Section ======= */}
          <Gallery />
          {/* End Gallery Section */}
          {/* ======= Testimonials Section ======= */}
          {/* <Testimonial/> */}
          {/* End Testimonials Section */}
          {/* ======= Team Section ======= */}
          <Team />
          {/* End Team Section */}
          {/* ======= F.A.Q Section ======= */}
          <Faq />
          {/* End F.A.Q Section */}
          {/* ======= Contact Section ======= */}
          <Contact />
          {/* End Contact Section */}
        </main>
        {/* End #main */}
      </div>
    </>
  );
};

export default function Popup({ onClose }) {
  useEffect(() => {
    const modalButton = document.getElementById("modalButton");
    if (modalButton) {
      modalButton.click();
    }
    return () => {
      onClose();
    };
  }, [onClose]);

  return (
    <>
      <button
        type="button"
        id="modalButton"
        class="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        style={{ display: "none" }}
      >
       
      </button>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <img style={{width:"40px",height:"55px"}} src={logo} alt="logo" />
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              Dear students,
              <br />
              <b>28th November will be the last day of online
              registration … Further registration will start on school premise
              from 2nd December 9 AM . </b>You can do offline and online
              registration on that day too . There will be special registration
              on 29th Nov , 30th Nov and 1st December only through batch
              coordinator . Please contact them directly on those days…
              <br /><br /> Regards
              AAHCS
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
