import React from "react";

export const Faq = () => {
  return (
    <>
      <section id="faq" className="faq section-bg">
        <div className="container mt-5">
          <div className="section-title" data-aos="fade-up">
            <h2>F.A.Q</h2>
            <p>Frequently Asked Questions</p>
          </div>
          <div className="faq-list">
            <ul>
              <li data-aos="fade-up">
                <i className="bx bx-help-circle icon-help" />{" "}
                <a
                  data-bs-toggle="collapse"
                  className="collapse"
                  data-bs-target="#faq-list-1"
                >
                  How can I become a member of the Holy Child School Alumni Association?{" "}
                  <i className="bx bx-chevron-down icon-show" />
                  <i className="bx bx-chevron-up icon-close" />
                </a>
                <div
                  id="faq-list-1"
                  className="collapse show"
                  data-bs-parent=".faq-list"
                >
                  <p>
                  To become a member, visit our website and navigate to the "Membership" section. Click on the "Join Now" button and fill out the membership form. Once you submit the form, you will be directed to the payment page to complete your membership payment. Upon successful payment, you will become a member of the association.
                  </p>
                </div>
              </li>
              <li data-aos="fade-up" data-aos-delay={100}>
                <i className="bx bx-help-circle icon-help" />{" "}
                <a
                  data-bs-toggle="collapse"
                  data-bs-target="#faq-list-2"
                  className="collapsed"
                >
                  What are the benefits of becoming a member?{" "}
                  <i className="bx bx-chevron-down icon-show" />
                  <i className="bx bx-chevron-up icon-close" />
                </a>
                <div
                  id="faq-list-2"
                  className="collapse"
                  data-bs-parent=".faq-list"
                >
                  <p>
                  As a member of the Holy Child School Alumni Association, you gain access to a wide range of benefits. These include exclusive alumni events, networking opportunities, job placement assistance, access to the alumni directory, newsletters, and updates on school activities. Additionally, members may also enjoy discounts on certain association-sponsored events and merchandise.
                  </p>
                </div>
              </li>
              <li data-aos="fade-up" data-aos-delay={200}>
                <i className="bx bx-help-circle icon-help" />{" "}
                <a
                  data-bs-toggle="collapse"
                  data-bs-target="#faq-list-3"
                  className="collapsed"
                >
                 How do I make a payment for membership?{" "}
                  <i className="bx bx-chevron-down icon-show" />
                  <i className="bx bx-chevron-up icon-close" />
                </a>
                <div
                  id="faq-list-3"
                  className="collapse"
                  data-bs-parent=".faq-list"
                >
                  <p>
                  We offer secure online payment options for membership. Once you have filled out the membership form, you will be redirected to the payment page. You can make the payment using various methods such as credit/debit cards or online payment platforms. If you prefer offline payment, please contact our support team for further assistance.
                  </p>
                </div>
              </li>
              <li data-aos="fade-up" data-aos-delay={300}>
                <i className="bx bx-help-circle icon-help" />{" "}
                <a
                  data-bs-toggle="collapse"
                  data-bs-target="#faq-list-4"
                  className="collapsed"
                >
                 Can I make a donation to the Holy Child School Alumni Association without becoming a member? <i className="bx bx-chevron-down icon-show" />
                  <i className="bx bx-chevron-up icon-close" />
                </a>
                <div
                  id="faq-list-4"
                  className="collapse"
                  data-bs-parent=".faq-list"
                >
                  <p>
                  Yes, absolutely! We appreciate your support. If you wish to make a donation to the association without becoming a member, you can visit our website's "Donation" section. There, you will find options to make a one-time donation or set up recurring donations. Your contribution will go a long way in supporting our initiatives and programs.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};
