import React, { useState, useEffect } from "react";
import axios from "axios";

const NoticeSection = () => {
  const authToken = localStorage.getItem("HCAA-TOKEN") || null;
  const userId = localStorage.getItem("HCAA-USER-ID") || null;
  const [notices, setNotices] = useState([]);
  const [state, setState] = useState(false);
  const [user, setUser] = useState([]);
  const [updateFormData, setUpdateFormData] = useState({
    id: "",
    title: "",
    venue: "",
    url:"",
    description: "",
    timing: "",
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `https://hcaa-backend-v7.onrender.com/v4/user/${userId}`,
          {
            headers: { Authorization: authToken },
          }
        );
        setUser(response.data.user);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchUserData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://hcaa-backend-v7.onrender.com/v4/notice"
      );
      setNotices(response.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, [notices]);



  const handleDelete = async (id) => {
    const bool = window.confirm("Notice with Id : " + id + " will be deleted");
    if (bool) {
      try {
        const response = await axios.delete(
          `https://hcaa-backend-v7.onrender.com/v4/notice/${id}`,
          {
            headers: {
              Authorization: authToken,
            },
          }
        );
        if (response.status === 204) {
          alert("Notice deleted successfully");
        } else {
          alert("Failed to delete notice.");
        }
      } catch (error) {
        alert("Error deleting notice:", error.message);
      }
    }
  };
   
  const handleUpdateFormChange = (e) => {
    const { name, value } = e.target;
    setUpdateFormData({
      ...updateFormData,
      [name]: value,
    });
  };

  const handleUpdate = (id) => {
    setState(true);
    const noticeToUpdate = notices.find((notice) => notice._id === id);
    setUpdateFormData({
      id: noticeToUpdate._id,
      title: noticeToUpdate.title,
      venue: noticeToUpdate.venue,
      url:noticeToUpdate.url,
      description: noticeToUpdate.description,
      timing: noticeToUpdate.timing,
    });
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `https://hcaa-backend-v7.onrender.com/v4/notice/${updateFormData.id}`,
        updateFormData,
        {
          headers: {
            Authorization: authToken,
          },
        }
      );
      if (response.status === 200) {
        alert("Notice updated successfully");
        setState(false);
        fetchData();
      } else {
        alert("Failed to update notice.");
      }
    } catch (error) {
      alert("Error updating notice: " + error.message);
      console.log(error);
    }
  };



  return (
    <section id="gallery" className="gallery">
      <div className="container">
        <div className="section-title mt-5" data-aos="fade-up">
          <h2>Notice</h2>
          <p>Notice board</p>
          {user.isAdmin && (
            <a href="/admin_notice">
              <u> post notice</u>
            </a>
          )}
        </div>
        {notices.length === 0 ? (
          "No Notice Found"
        ) : (
          <div className="container">
            {notices.map((notice) => (
              <div key={notice.id} className="card mb-3 ">
                <div className="card-header">
                  <h2 className="card-title">{notice.title}</h2>
                  <p>
                    <small>ID : {notice._id}</small>
                  </p>
                </div>
                <div className="card-body">
                  {notice.venue !== "" && (
                    <p className="card-text">
                      <strong>Venue:</strong> {notice.venue}
                    </p>
                  )}
                  <p className="card-text">
                    <strong>Description:</strong> {notice.description}
                  </p>
                 {notice.url !== "" &&  <p className="card-text">
                    <strong>Link :</strong> <a href={notice.url} target="_blank">{notice.url}</a> 
                  </p>
}

                  {notice.timing !== "" && (
                    <p className="card-text">
                      <strong>Date : </strong>{" "}
                      {new Date(notice.timing).toLocaleDateString()},
                    </p>
                  )}
                  {notice.timing !== "" && (
                    <p className="card-text">
                      <strong>Time :</strong>{" "}
                      {new Date(notice.timing).toLocaleTimeString()}
                    </p>
                  )}
                  {user.isAdmin && (
                    <div>
                      {" "}
                      <button
                        onClick={() => handleUpdate(notice._id)}
                        class="btn btn-primary"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight"
                      >
                        Update
                      </button>{" "}
                      <div
                        class="offcanvas offcanvas-end"
                        tabindex="-1"
                        id="offcanvasRight"
                        aria-labelledby="offcanvasRightLabel"
                      >
                        <div class="offcanvas-header">
                        
                          <span>ID : {updateFormData.id}</span>
                          <button
                            type="button"
                            class="btn-close text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div class="offcanvas-body">
                          <form  className="row g-3 w-100" id="edit" onSubmit={handleUpdateSubmit}>
                            <label>Title</label>
                            <input
                              className="form-control"
                              type="text"
                              name="title"
                              value={updateFormData.title}
                              onChange={handleUpdateFormChange}
                            />

                            <label>Venue</label>
                            <input
                              className="form-control"
                              type="text"
                              name="venue"
                              value={updateFormData.venue}
                              onChange={handleUpdateFormChange}
                            />
                            <label>Link</label>
                            <input
                              className="form-control"
                              type="url"
                              name="url"
                              value={updateFormData.url}
                              onChange={handleUpdateFormChange}
                            />


                            <label>Description</label>
                            <input
                              className="form-control"
                              type="text"
                              name="description"
                              value={updateFormData.description}
                              onChange={handleUpdateFormChange}
                            />

                            <label>Timing</label>
                            <input
                              className="form-control"
                              type="datetime-local"
                              name="timing"
                              value={updateFormData.timing}
                              onChange={handleUpdateFormChange}
                            />

                            <button
                              type="submit"
                              className="btn btn-primary"
                              data-bs-dismiss="offcanvas"
                            >
                              Update Notice
                            </button>
                          </form>
                        </div>
                      </div>
                      <button
                        onClick={() => handleDelete(notice._id)}
                        className="btn btn-warning"
                      >
                        DELETE
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default NoticeSection;
