import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "./Images/new-logo-aahcs-removebg.png";
import axios from "axios";

export const Alumni = () => {
  const [user, setUser] = useState([]);
  const [alumni, setAlumni] = useState([]);
  const [payment, setPayment] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const userId = localStorage.getItem("HCAA-USER-ID") || null;
  const authToken = localStorage.getItem("HCAA-TOKEN") || null;

  if (!userId && !authToken) {
    navigate("/login");
  }

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `https://hcaa-backend-v7.onrender.com/v4/user/${userId}`,
          {
            headers: { Authorization: authToken },
          }
        );
        setUser(response.data.user);
        if (user) {
          setAlumni(response.data.user.alumni);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.log("Error fetching user data:");
      }
    };
    fetchUserData();
  }, []);

  useEffect(() => {
    if (user) {
      const fetchUserData = async () => {
        try {
          const response = await axios.get(
            `https://hcaa-backend-v7.onrender.com/v4/alumni/${alumni[0]._id.toString()}`,
            {
              headers: { Authorization: authToken },
            }
          );
          setPayment(response.data.paymentDetails);
        } catch (error) {
          console.error("Error fetching user data");
        }
      };

      fetchUserData();
    }
  }, [alumni]);

  if (isLoading) {
    return (
      <div style={{ margin: "12rem 0 7rem 0" }}>
        <div className="text-center">
          {/* <img src={logo} alt="logo" className="image-fuild" style={{width:"80px"}} />
          <br />
          <h1>loading....</h1>  */}
          <div className="spinner-border" role="status"></div>
          <br />
          <span className="">Loading...</span>
        </div>
      </div>
    );
  }

  const handleLogout = () => {
    const bool = window.confirm("Are You Sure ? ");
    if (bool) {
      localStorage.removeItem("HCAA-USER-ID");
      localStorage.removeItem("HCAA-TOKEN");
      localStorage.removeItem("ALUMNI");
      {
        <div style={{ margin: "12rem 0 7rem 0" }}>
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>;
      }
      setTimeout(() => {
        window.location.href = "/";
      }, 1200);
    }
  };

  return (
    <section id="gallery" className="gallery">
      <div className="container my-5">
        <div className="section-title" data-aos="fade-up">
          <h2>dashboard</h2>
          <p>Welcome {user.name}</p>
        </div>
        <div className="card  user-table" style={{ marginTop: "-2rem" }}>
          <div className="card-body">
            <table className="table table-bordered ">
              <tbody>
                <tr>
                  <th>Name</th>
                  <td>{user.name}</td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{user.email}</td>
                </tr>
                {alumni?.length > 0 && (
                  <tr>
                    <th>Spouse Name</th>
                    <td>{alumni[0].spouse ? alumni[0].spouse : "N/A"}</td>
                  </tr>
                )}
                {alumni?.length > 0 && (
                  <tr>
                    <th>Mobile</th>
                    {alumni[0].phoneNumber?.toString().length > 10 ? (
                      <td>+{alumni[0].phoneNumber} </td>
                    ) : (
                      <td>{alumni[0].phoneNumber}</td>
                    )}
                  </tr>
                )}
                {alumni?.length > 0 && (
                  <tr>
                    <th>Food Preference</th>
                    <td>{alumni[0].food}</td>
                  </tr>
                )}
                {alumni?.length > 0 && (
                  <tr>
                    <th>Batch</th>
                    <td>{alumni[0].batch}</td>
                  </tr>
                )}
                {alumni?.length > 0 && (
                  <tr>
                    <th>Year of Passout</th>
                    <td>{alumni[0].year}</td>
                  </tr>
                )}

                {alumni?.length > 0 && (
                  <tr>
                    <th>Address</th>
                    <td>{alumni[0].permanentAddress}</td>
                  </tr>
                )}
                {alumni?.length > 0 && (
                  <tr>
                    <th>Registration</th>
                    <td>Completed</td>
                  </tr>
                )}
                <tr>
                  <th>Event Fees </th>
                  <td>
                    {payment.length > 0 ? (
                      "subject to verification"
                    ) : (
                      <>
                        {" "}
                        <b>Not Paid</b>{" "}
                        <Link to="/alumni-registration">
                          {" "}
                          <span style={{ color: "green", cursor: "pointer" }}>
                            (pay now)
                          </span>
                        </Link>
                      </>
                    )}
                  </td>
                </tr>
                {payment.map((el) => (
                  <tr>
                    <th>
                      Payment ID : <small>{el.transactionID}</small>
                    </th>

                    <td>
                      <b>
                        {el.isVerified === false
                          ? "Under Verification"
                          : "Verified"}
                      </b>{" "}
                      | Paid : <b> ₹{el.amount}/-</b> | On : <b> {el.date}</b>
                    </td>
                  </tr>
                ))}

                {/* {payment.map((el) => (
                  <tr>
                    <th>
                      Payment ID : <small>{el.razorpay_payment_id}</small>
                    </th>

                    <td>
                      <b>{el.eventType}</b> | Paid : <b> ₹{el.amount}/-</b> |
                      Date : <b>{el.date} </b>
                    </td>
                  </tr>
                ))} */}
              </tbody>
            </table>
           
            <br />
            <div
              data-aos="fade-up"
              style={{ display: "flex", justifyContent: "end" }}
            >
              {user.isAdmin && (
                <button
                  onClick={() => (window.location.href = "/admin")}
                  className="btn btn-info w-100"
                >
                  admin console
                </button>
              )}{" "}
              &nbsp;
              {/* {user.isAdmin && (
                <button
                  onClick={() => (window.location.href = "/admin-alumni-registration")}
                  className="btn btn-success w-100"
                >
                  Admin Registerer
                </button>
              )}{" "}
              &nbsp; */}
              <button onClick={handleLogout} className="btn btn-danger w-100">
                Log Out
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
