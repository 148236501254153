import React, { useEffect, useState } from "react";
import axios from "axios";
import { checkoutHandler } from "../Api/payment.api.js";

const CulturalProgram = () => {
  const authToken = localStorage.getItem("HCAA-TOKEN") || null;
  const userId = localStorage.getItem("HCAA-USER-ID") || null;

  const [decoded, setDecoded] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `https://hcaa-backend-v7.onrender.com/v4/user/${userId}`,
          {
            headers: { Authorization: authToken },
          }
        );
        setDecoded(response.data.user);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchUserData();
  }, []);

  return (
    <section id="gallery" className="gallery">
      <div className="container mt-5">
        <div className="section-title" data-aos="fade-up">
          <h2>Program</h2>
          <p>Cultural Program</p>
          {authToken && <h4>Welcome {decoded.name} </h4>}
        </div>
        <div className="container">
          <h4>
            The Cultural Program organized by the Holy Child Alumni Association
            is an enchanting event that celebrates the rich cultural diversity
            and talent within our alumni community. This vibrant gathering
            promises an evening of captivating performances, artistic
            expressions, and an opportunity to immerse yourself in the world of
            culture and creativity.
          </h4>
          <br />
          <p>
            <mark>Cost: ₹500 per person</mark>
          </p>
        </div>
        {authToken && decoded.alumni?.length !== 0 ? (
          <div data-aos="fade-up">
            <button
              onClick={async () =>
                await checkoutHandler(500, authToken, "Cultural Program")
              }
              disabled={decoded.alumni?.length == 0}
              className="btn btn-success w-100"
            >
              ₹ 500
            </button>
          </div>
        ) : (
          <div class="alert alert-info" role="alert">
           You need to become a member first 
          </div>
        )}
      </div>
    </section>
  );
};

export default CulturalProgram;
