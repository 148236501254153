import React from "react";
import rajib from "./team/rajib.jpeg"
import poulami from "./team/poulami.jpeg"
import Amit from "./team/Amit.jpeg"
import sanjay from './team/sanjay1.jpeg'
import hambir from './team/hambir.jpeg'
import vineet from './team/vinnet.jpeg'
import priyanshu from './team/priyanshu.jpeg'
import gopa from './team/gopa.jpeg'
import ricky from "./team/ricky.jpeg"
import deb from "./team/debajyoti.jpeg"
import subhsis from "./team/subhsis.jpeg"
import tirthankar from "./team/tirthankar.jpeg"
import ankit from "./team/ankit.jpeg"
import anand from "./team/anand.jpeg"
import pranay from './team/pranay.jpeg'
import niladri from './team/niladri.jpeg'
import rajesh from './team/rajesh.jpeg'
import jayanta from './team/jayanta.jpeg'
import hirak from './team/hirak.jpeg'
import parag from './team/parag1.jpg'
import bhaskar from './team/bhaskar.jpeg'


const teamMembers = [
  {
    id: 1,
    name: "Debajyoti Ghosh",
    icse_year: 1988,
    address: "Mission Road Ward 10, Ashrampara Jalpaiguri",
    role: "Working President",
    img: deb
  },
  {
    id: 2,
    name: "Manohar Kha Kha",
    icse_year: 1988,
    address: "Tarapara Jalpaiguri",
    role: "Vice President",
    img: "https://www.ihna.edu.au/blog/wp-content/uploads/2022/10/user-dummy.png"
  },
  {
    id: 3,
    name: "Jayanta Sarkar",
    icse_year: 1985,
    address: "Sirishtala Ward 21, Jalpaiguri",
    role: "Vice President",
    img: jayanta
  },
  {
    id: 4,
    name: "Biswajit Mallick",
    icse_year: 1986,
    address: "Mohitnagar Mundabasti Jalpaiguri",
    role: "Vice President",
    img: "https://www.ihna.edu.au/blog/wp-content/uploads/2022/10/user-dummy.png"
  },
  {
    id: 5,
    name: "Sanjay Kr Agarwalla",
    icse_year: 1987,
    address: "Maynaguri, Dt Jalpaiguri",
    role: "Vice President",
    img: sanjay
  },
  {
    id: 6,
    name: "Hambir Ghosh",
    icse_year: 1988,
    address: "Samajpara Ward 5, Jalpaiguri",
    role: "Vice President",
    img: hambir
  },
  {
    id: 7,
    name: "Hirak Barman",
    icse_year: 1989,
    address: "Natunpara Ward 7, Jalpaiguri",
    role: "Vice President",
    img: hirak
  },
  {
    id: 8,
    name: "Niladri Bhowmick",
    icse_year: 1990,
    address: "Kadamtala Jalpaiguri",
    role: "Vice President",
    img:niladri
  },
  {
    id: 9,
    name: "Parag Sarkar",
    icse_year: 1991,
    address: "Pabitrapara Jalpaiguri",
    role: "Vice President",
    img: parag
  },
  {
    id: 10,
    name: "Subhasis Ghosh",
    icse_year: 1988,
    address: "Uttarkanya Jalpaiguri",
    role: "General Secretary",
    img: subhsis
  },
  {
    id: 11,
    name: "Vineet Khoria",
    icse_year: 1993,
    address: "M G Road Jalpaiguri",
    role: "Assistant Secretary",
    img: vineet
  },
  {
    id: 12,
    name: "Vishant Chowdhury",
    icse_year: 1993,
    address: "M G Road Jalpaiguri",
    role: "Assistant Secretary",
    img: "https://www.ihna.edu.au/blog/wp-content/uploads/2022/10/user-dummy.png"
  },
  {
    id: 13,
    name: "Sunit Kr Basu",
    icse_year: 1994,
    address: "Raikatpara Jalpaiguri",
    role: "Assistant Secretary",
    img: "https://www.ihna.edu.au/blog/wp-content/uploads/2022/10/user-dummy.png"
  },
  {
    id: 14,
    name: "Gopa Sinha",
    icse_year: 1995,
    address: "Kadamtala Jalpaiguri",
    role: "Assistant Secretary",
    img: gopa
  },
  {
    id: 15,
    name: "Tirthankar Kanti Dey",
    icse_year: 1992,
    address: "Shibaji Road Jalpaiguri",
    role: "Convenor Cultural Cell",
    img: tirthankar
  },
  {
    id: 16,
    name: "Bhaskar Sarkar",
    icse_year: 1993,
    address: "Mohanta Para Ward 19, Jalpaiguri",
    role: "Convenor Sports Cell",
    img: bhaskar
  },
  {
    id: 17,
    name: "Anand Agarwal",
    icse_year: 1993,
    address: "Dinbazar Jalpaiguri",
    role: "Convenor Social and Welfare Works Cell",
    img: anand
  },
  {
    id: 18,
    name: "Rajib Goon",
    icse_year: 1996,
    address: "Netaji Subhash Bose Rd, Rajbaripara Jalpaiguri",
    role: "Convenor Publicity/Press/IT Cell",
    img: rajib
  },
  {
    id: 19,
    name: "Poulomee Saha",
    icse_year: 1998,
    address: "J N Road Chennai",
    role: "Convenor E-Liaison Cell",
    img: poulami
  },
  {
    id: 20,
    name: "Pranay Das",
    icse_year: 2005,
    address: "Darjipara Jalpaiguri",
    role: "Convenor Literary Cell",
    img: pranay
  },
  {
    id: 21,
    name: "Ankit Kr Singhi",
    icse_year: 2005,
    address: "Dinbazar Ward 5, Jalpaiguri",
    role: "Convenor Health/Blood/Eye Camp",
    img: ankit
  },
  {
    id: 22,
    name: "Amit Kr Sardiwal",
    icse_year: 2003,
    address: "Dinbazar Ward 5, Jalpaiguri",
    role: "Treasurer",
    img: Amit
  },
  {
    id: 23,
    name: "Koyalee Sinha Das",
    icse_year: 1999,
    address: "Merchant Road, Jalpaiguri",
    role: "Assistant Treasurer",
    img: "https://www.ihna.edu.au/blog/wp-content/uploads/2022/10/user-dummy.png"
  },
  {
    id: 24,
    name: "Rajesh Jain",
    icse_year: 2001,
    address: "Merchant Road Jalpaiguri",
    role: "Executive Member",
    img: rajesh
  },
  {
    id: 25,
    name: "Angsu Priya Nag",
    icse_year: 2002,
    address: "Ashoke Nagar Jalpaiguri",
    role: "Executive Member",
    img: priyanshu
  }
]


export const Team = () => {
  return (
    <section id="team" className="team">
      <div className="container mt-5">
        <div className="section-title" data-aos="fade-up">
          <h2>BOARD</h2>
          <p>Our BOARD MEMBERS</p>
        </div>
        <div className="row" data-aos="fade-left">
          {teamMembers.map((member, index) => (
            <div
              className="col-lg-3 col-md-6"
              key={index}
              data-aos="zoom-in"
              data-aos-delay={100 * index}
            >
              <div className="member">
                <div className="pic">
                  <img
                    style={{height:"250px",width:"220px"}}
                    src={member.img}
                    className="img-fluid mt-3"
                    alt={member.name}
                  />
                </div>
                <div className="member-info">
                  <h4>{member.name}</h4>
                  <p>ICSE Year : {member.icse_year}</p>
                  <span>{member.role}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
