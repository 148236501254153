import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../Redux/Action/login.action.js";
import axios from "axios";

//:::::::::::::::::::: MAIN FUNCTION ::::::::::::::::::::::
export const Login = () => {
  const [userLog, setUserLog] = useState({
    email: "",
    password: "",
  });
  const [isVisable, setisVisable] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, error, isSuccess } = useSelector((state) => state.login);
  const [loading, setLoading] = useState(false);
  const [res, setRes] = useState("");
  const [restmail, setResetMail] = useState("");

  //::::::::::::::: handling the input :::::::::::::::::
  const handleInput = (e) => {
    const { name, value } = e.target;
    setUserLog({ ...userLog, [name]: value });
  };
  //::::::::::::::::  handle login function :::::::::::::::::
  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(login(userLog));
  };

  if (isSuccess) {
    alert("Login Successful");
    setTimeout(() => {
      window.location.href = "/";
    }, 1200);
  }

  const handleViewPassword = () => {
    setisVisable(!isVisable);
  };

  // forget password

  const handleForget = async () => {
    try {
      setLoading(true);
      const res = await axios.post("https://hcaa-backend-v7.onrender.com/v4/reset-password", {
        email: userLog.email,
      });
      setLoading(false);
      console.log(res)
      if (res.data.status) {
        setResetMail("No user Found");
      }
      else {
        setResetMail(
          `A mail has been sent to you ,You can reset password with 5 min ,check your spam folders too`
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  //::::::::::::: main HTML function ::::::::::::::::::

  return (
    <>
      <form className="row g-3">
        <div className="alert alert-warning" role="alert">
          still in developing phase, We Request You to use SIMPLE 8 character
          Password.
        </div>
        <p style={{ marginBottom: "-0.5rem" }}>
          {" "}
          <a href="/signup">Signup</a>
          {" > "} <a href="/login">Log In</a>
        </p>

        <h2>
          {" "}
          <b>LOG IN</b>{" "}
        </h2>
        {/* <div className="progress" style={{ marginTop: "-0.5em" }}>
          <div
            className="progress-bar progress-bar-striped"
            role="progressbar"
            style={{ width: "66%" }}
            aria-valuenow="66"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div> */}
        <hr />
        <div className="mb-3">
          <label htmlFor="validationDefault02" className="form-label">
            Email Address
          </label>
          <input
            type="text"
            className="form-control"
            id="validationDefault02"
            value={userLog.email}
            name="email"
            onChange={handleInput}
            required
            placeholder="enter email"
          />
        </div>

        <div className="mb-3">
          <label htmlFor="validationDefault05" className="form-label">
            Password
          </label>
          <div className="input-group">
            <input
              type={!isVisable ? "password" : "text"}
              className="form-control"
              id="validationDefault05"
              value={userLog.password}
              name="password"
              onChange={handleInput}
              required
              minLength={8}
              placeholder="enter password"
            />
            <span className="input-group-text" id="inputGroupPrepend2">
              <input
                className="d-none"
                type="checkbox"
                id="passwordCheckbox"
                onClick={handleViewPassword}
              />
              {isVisable ? (
                <label htmlFor="passwordCheckbox">
                  <i className="bi bi-eye"></i>
                </label>
              ) : (
                <label htmlFor="passwordCheckbox">
                  <i className="bi bi-eye-slash"></i>
                </label>
              )}
            </span>
          </div>
        </div>
        <div className="col-12">
          <div className="form-check">
            {/* <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="invalidCheck2"
              required
            />
            <label className="form-check-label" htmlFor="invalidCheck2">
              Remember Me
            </label> */}
            {error && (
              <p style={{ color: "red" }}>wrong email or password...</p>
            )}
          </div>
          <h6
            style={{ cursor: "pointer", color: "green" }}
            onClick={handleForget}
          >
            {" "}
            <b>{loading ? "checking ..." : "forgot password"}</b>
          </h6>
            {restmail && <p><u>{restmail}</u></p>}
        </div>
        <div className="col-12">
          <div className="form-check">
            <label className="form-check-label" htmlFor="invalidCheck2">
              Don't have an Account ? <a href="/signup"> Sign Up </a> here
            </label>
          </div>
        </div>
        <div className="col-12">
          {!isLoading ? (
            <button
              onClick={handleLogin}
              className="btn btn-primary w-100"
              type="submit"
            >
              LOG IN
            </button>
          ) : (
            <button className="btn btn-primary w-100" type="button" disabled>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>{" "}
              Loading...
            </button>
          )}
        </div>
      </form>
    </>
  );
};
