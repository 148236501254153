import React, { useState, useEffect } from "react";
import axios from "axios";
import qr from "./aahcs-payment.jpeg";
import { useNavigate } from "react-router";
import jwt_decode from "jwt-decode";

export default function NewTempPay() {
  const [alumni, setAlumni] = useState([]);
  const [loading ,setLoading] = useState(false)
  const authToken = localStorage.getItem("HCAA-TOKEN") || null;
  var decodedJwt = jwt_decode(authToken);
  const [formData, setFormData] = useState({
    name: "" || decodedJwt.name,
    batch: "" || decodedJwt.batch,
    year: "" || decodedJwt.passoutYear,
    gender: "" || decodedJwt.gender,
    food: "",
    phoneNumber: "" || "+" + decodedJwt.mobile,
    email: "" || decodedJwt.email,
    permanentAddress: "" || decodedJwt.permanentAddress,
    spouse: "",
    transactionId: "",
    amount: "",
    date: "",
  });
  //------------------------------------------
  const userId = localStorage.getItem("HCAA-USER-ID") || null;
  const [decoded, setDecoded] = useState([]);
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `https://hcaa-backend-v7.onrender.com/v4/user/${userId}`,
          {
            headers: { Authorization: authToken },
          }
        );
        setDecoded(response.data.user);
        if (decoded) {
          setAlumni(response.data.user.alumni);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true)
      const response = await axios.post(
        "http://127.0.0.1:8801/v4/new-alumni-registration",
        formData,
        {
          headers: { Authorization: authToken },
        }
      );
      setLoading(false)
      if (response.status === 201) {
        alert("Registration Succesfull...");
      } else {
        alert("Something went wrong ... Try again");
      }
    } catch (error) {
      setLoading(false)
      alert(error.response.data.error);
    }
  };

  //-----------------------------------------------
  return (
    <section id="gallery" className="gallery">
      <div className="container mt-5">
        <div className="section-title" data-aos="fade-up">
          <h2>Registration</h2>
          <p>EVENT REGISTRATION</p>
          {authToken && <h4>Welcome {decoded.name} </h4>}
        </div>
        <form className="registration-form" onSubmit={handleSubmit}>
          <div className=" mb-3" style={{ marginTop: "-8rem" }}>
            <label htmlFor="name" className="form-label">
              Name
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              value={formData.name || decodedJwt.name}
              onChange={handleChange}
              required
              disabled
            />
          </div>

          <div className="mb-3">
            <label htmlFor="permanentAddress" className="form-label">
              Permanent Address
            </label>
            <input
              type="text"
              className="form-control"
              id="permanentAddress"
              name="permanentAddress"
              value={formData.permanentAddress || decodedJwt.permanentAddress}
              onChange={handleChange}
              required
              disabled
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Batch</label>
            <div>
              <input
                type="radio"
                id="icse"
                name="batch"
                value="ICSE"
                checked={formData.batch === "ICSE"}
                onChange={handleChange}
                disabled
              />
              <label htmlFor="icse" className="ml-2">
                &nbsp;ICSE
              </label>
            </div>
            <div>
              <input
                type="radio"
                id="Other-than-ISC-ICSE"
                name="batch"
                value="Other than ISC ICSE"
                checked={formData.batch === "Other than ISC ICSE"}
                onChange={handleChange}
                disabled
              />
              <label htmlFor="schoolLeavingYear" className="ml-2">
                &nbsp;Others
              </label>
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="year" className="form-label">
              {formData.batch} Year
            </label>
            <select
              className="form-select"
              id="year"
              name="year"
              value={formData.year || decodedJwt.passoutYear}
              onChange={handleChange}
              required
              disabled
            >
              <option value="" selected>
                {formData.batch} Year
              </option>
              {generateYearOptions(1985, new Date().getFullYear())}
            </select>
            <p style={{ color: "blue" }}>
              <b>NOTE : </b> ICSE 2017 & ISC 2019 onwards Event Fee :{" "}
              <b>Rs 500/-</b>
              <br />
              All previous Batches : <b>Rs 1500/-</b>
            </p>
          </div>
          <div className="mb-3">
            <label htmlFor="gender" className="form-label">
              Gender
            </label>
            <select
              className="form-select"
              id="gender"
              name="gender"
              value={formData.gender || decodedJwt.gender}
              onChange={handleChange}
              required
              disabled
            >
              <option value="" selected disabled>
                select your gender
              </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="food" className="form-label">
              Food Preference
            </label>
            <select
              className="form-select"
              id="food"
              name="food"
              value={formData.food}
              onChange={handleChange}
              required
            >
              <option value="" selected disabled>
                select your food preference
              </option>
              <option value="Veg">Veg</option>
              <option value="Non-Veg">Non-Veg</option>
            </select>
          </div>

          <div className="mb-3">
            <label htmlFor="phoneNumber" className="form-label">
              Phone Number
            </label>
            <input
              type="tel"
              maxLength={10}
              className="form-control"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber || decodedJwt.phoneNumber}
              onChange={handleChange}
              required
              disabled
            />
          </div>

          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email Address
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={formData.email || decodedJwt.email}
              onChange={handleChange}
              required
              disabled
            />
          </div>
          <div className="mb-3">
            <label htmlFor="price" className="form-label">
              Spouse Registration (optional)
            </label>
            <input
              type="text"
              placeholder="enter spouse name"
              name="spouse"
              value={formData.spouse}
              className="form-control"
              onChange={handleChange}
            />
            <p style={{ color: "red" }}>
              <b>NOTE : </b> If you bring your spouse, you need to pay an
              additional <b> Rs 1500/-</b> This applies to all batches.
            </p>
          </div>
          {/* ------------------------------ */}
          <details>
            <summary>
              <b style={{ color: "green" }}>
                Click Here To see Payment Options
              </b>
            </summary>

            <div className="card mb-3">
              <div className="row g-0">
                <div className="col-md-4">
                  <img src={qr} className="img-fluid rounded-start" alt="..." />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <p className="card-text">
                      <h6>
                        Fee Structure Explanation:
                        <hr />
                        <h4>
                          <b> 1. ICSE 2017 & ISC 2019 onwards: Rs 500/- </b>
                        </h4>
                        <br />
                        <h4>
                          <b>2. All Others : Rs 1500/- </b>
                        </h4>
                        <br />
                        <u>Additional Information: </u>
                        If you bring one more person (e.g., spouse), you need to
                        pay an additional <b> Rs 1500/-</b> This applies to all
                        batches.
                      </h6>
                      <hr />
                      <b>BANK Details : </b>
                      <br />
                      AC no. 921010041186481
                      <br />
                      Alumni Association of Holy Child School Jalpaiguri
                      <br />
                      Axis Bank Dinbazar Jalpaiguri Branch
                      <br />
                      IFSC UTIB0004284
                      <br />
                      <ul>
                        <b>OR in any of the the following UPI</b>
                        <li>9933493456@paytm</li>
                        <li> sgwbcs810@axisbank </li>
                        <li> subhasis.wbgov@oksbi</li>
                      </ul>
                    </p>
                    <div className="card">
                      <b>NOTE :</b>{" "}
                      <u>
                        {" "}
                        There is no option of cancellation and refund. In case
                        of any query please contact through e mail.
                      </u>
                      <br />
                      Contact : alumnihcsjalpaiguri@gmail.com
                      <br />
                      Mob : 8116123456 (whatsapp)
                      <br />
                      Address : Hakimpara Jalpaiguri 735101
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </details>
          <br />

          {/* ------------------------------ */}
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Transaction ID
            </label>
            <input
              type="text"
              className="form-control"
              id="transactionId"
              name="transactionId"
              value={formData.transactionId}
              onChange={handleChange}
              required
              placeholder="enter your transaction ID here"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Amount Paid
            </label>
            <select
              onChange={handleChange}
              name="amount"
              value={formData.amount}
              className="form-select"
              required
            >
              <option value="" selected disabled>
                Select Amount Paid
              </option>
              <option value={500}>Rs 500</option>
              <option value={1500}>Rs 1500</option>
              <option value={2000}>Rs 2000</option>
              <option value={3000}>Rs 3000</option>
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Payment date
            </label>
            <input
              type="date"
              className="form-control"
              required
              name="date"
              value={formData.date}
              onChange={handleChange}
            />
          </div>
          <b style={{ color: "red" }}>
            {" "}
            NOTE : To ensure the accuracy and security of our records, we kindly
            request that you upload your Transaction ID for Verification,{" "}
            <u>and Passout Year Subject to Verification</u> .
          </b>
          <br />
          <br />
          <button type="submit" className="btn btn-success w-100" disabled={loading}>
           { loading ? "Registering..." : "Submit"}
          </button>
        </form>
      </div>
    </section>
  );
}

const generateYearOptions = (startYear, endYear) => {
  const options = [];
  for (let year = startYear; year <= endYear; year++) {
    options.push(
      <option key={year} value={year}>
        {year}
      </option>
    );
  }
  return options;
};
