import React from 'react'

const Shipping = () => {
  return (
    <div>
      <p>
        <b>Shipping : </b>
        Delivery shipment not applicable as this is not a goods providing concern.</p>
    </div>
  )
}

export default Shipping