import React from "react";
import { Routes, Route } from "react-router-dom";
import { Home } from "./Components/Home";
import { Signup } from "./Components/Auth/Signup";
import { Login } from "./Components/Auth/Login";
import { Alumni } from "./Components/Alumni";
import { About } from "./Components/About";
import { Features } from "./Components/Features";
import { Gallery } from "./Components/Gallery";
import { Team } from "./Components/Team";
import Registration from "./Components/More/Registration";
import Reunion from "./Components/More/Reunion";
import CulturalProgram from "./Components/More/CulturalProgram";
import { Membership } from "./Components/More/membership";
import NoticeSection from "./Components/Notice";
import PostNoticeForm from "./Components/Admin/noticeAdmin";
import AdminDashboard from "./Components/Admin/admin";
import AlumniCard from "./Components/Admin/alumniCard";
import PaymentSuccess from "./Components/More/success";
import DonationPayment from "./Components/DonationPayment";
import Donor from "./Components/Donor";
import Privacy from "./Components/Policy/Privacy";
import NewTempPay from "./Components/More/NewTempPay";
import AlumniRegisterer from "./Components/More/AdminRegisterer";
import Stop from "./Components/Error/Stop";
import StopSignup from "./Components/Error/StopSignup";
//import Donation from "./Components/Donation"

export const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/about" element={<About />}></Route>
      <Route path="/services" element={<Features />}></Route>
      <Route path="/gallery" element={<Gallery />}></Route>
      <Route path="/board-members" element={<Team />}></Route>
      <Route path="/notice" element={<NoticeSection />}></Route>
      {/* <Route path="/alumni-registration" element={<Registration />}></Route> */}
      <Route path="/reunion" element={<Reunion />}></Route>
      <Route path="/cultural-program" element={<CulturalProgram />}></Route>
      <Route path="/membership" element={<Membership />}></Route>
      {/* <Route path="/signup" element={<Signup />}></Route> */}
      <Route path="/login" element={<Login />}></Route>
      <Route path="/alumni-dashboard" element={<Alumni />}></Route>
      <Route path="/admin" element={<AdminDashboard />}></Route>
      <Route path="/admin_alumni" element={<AlumniCard />}></Route>
      <Route path="/admin_notice" element={<PostNoticeForm />}></Route>
      <Route path="/paymentsuccess" element={<PaymentSuccess />} />
      <Route path="/donor" element={<Donor />}></Route>
      <Route path="/donation-payment" element={<DonationPayment />}></Route>
      <Route path="/policy" element={<Privacy />}></Route>
      <Route path="/test" element={<NewTempPay />}></Route>

      <Route path="*" element={<Home />}></Route>
      <Route path="/alumni-registration" element={<Stop />}></Route>

      <Route path="/signup" element={<StopSignup />}></Route>
    </Routes>
  );
};

{
  /* <Route path="/donation-payment" element={<Stop />}></Route> */
  /* <Route
        path="/admin-alumni-registration"
        element={<AlumniRegisterer />}
      ></Route>
}
{
  /* <Route path="/alumni-registration" element={<Stop />}></Route>  */
}
{
  /* <Route path="/donation" element={<Donation />}></Route> */
}
{
  /* <Route path="/signup" element={<StopSignup />}></Route> */
}
{
  /* <Route path="/membership" element={<Stop />}></Route> */
}
