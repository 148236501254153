import React from "react";

export const Footer = () => {
  return (
    <>
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-6">
                <div className="footer-info">
                  <h3>A A H C S</h3>
                  <p className="pb-3">
                    <em>
                      Holy Child school is an unaided Christian minority school.
                      It includes classes I to XII.The course of studies
                      followed is Indian Certificate of Secondary Education
                      conducted by the Council for the Indian School Certificate
                      Examination, New Delhi.
                    </em>
                  </p>
                  <p>
                    MOHITNAGAR <br />
                    JALPAIGURI 735101, WB
                    <br />
                    <br />
                    <strong>Phone:</strong> +91 8116123456
                    <br />
                    <strong>Email:</strong> alumnihcsjalpaiguri@gmail.com
                    <br />
                  </p>
                  <div className="social-links mt-3">
                    {/* <a href="#" className="twitter">
                      <i className="bx bxl-twitter" />
                    </a> */}
                    <a href="#" className="facebook">
                      <i className="bx bxl-facebook" />
                    </a>
                    {/* <a href="#" className="instagram">
                      <i className="bx bxl-instagram" />
                    </a> */}
                    {/* <a href="#" className="google-plus">
                      <i className="bx bxl-skype" />
                    </a>
                    <a href="#" className="linkedin">
                      <i className="bx bxl-linkedin" />
                    </a> */}
                  </div>
                  <a
                    href="/policy"
                    target="_blank"
                  >
                    Privacy & Policy
                  </a>{"|"}
                  <a
                    href="/policy"
                    target="_blank"
                  >
                    Terms & Conditions
                  </a>{"|"}
                  <a
                    href="/policy"
                    target="_blank"
                  >
                    Refund Policy
                  </a>{"|"}
                  <a
                    href="/policy"
                    target="_blank"
                  >
                    Shipping
                  </a>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 footer-links">
                {/* <h4>Useful Links</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right" /> <a href="#">Home</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <a href="#">About us</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <a href="#">Services</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <a href="#">Terms of service</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <a href="#">Privacy policy</a>
                  </li>
                </ul> */}
              </div>
              <div className="col-lg-2 col-md-6 footer-links">
                {/* <h4>Our Services</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <a href="#">Web Design</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <a href="#">Web Development</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <a href="#">Product Management</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <a href="#">Marketing</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right" />{" "}
                    <a href="#">Graphic Design</a>
                  </li>
                </ul> */}
              </div>
              <div className="col-lg-4 col-md-6 footer-newsletter">
                {/* <h4>Give Your Feedback</h4>
                <p>
                  Tamen quem nulla quae legam multos aute sint culpa legam
                  noster magna
                </p>
                <form action method="post">
                  <input type="email" name="email" />
                  <input type="submit" defaultValue="Subscribe" />
                </form> */}
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="copyright">
            © Copyright{" "}
            <strong>
              <span>Holy Child Alumni Association</span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits">
            Designed by{" "}
            <a href="https://rg-tech.in" target="_blank">
              RG Technologies
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};
